import React from 'react';
import { Layout } from 'grab-ui';
import classnames from 'classnames';
import Routes from './Routes';
import styles from './AppContent.less';
const { Content } = Layout;
export default function AppContent() {
    return (React.createElement(Content, { className: classnames(styles.content) },
        React.createElement("div", { className: classnames(styles.page) },
            React.createElement(Routes, null))));
}
AppContent.displayName = 'AppContent';
