var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import I18n from 'i18n-js';
import Raven from 'raven-js';
import { getLocaleFromBrowserLanguage } from 'grab-locale';
import Storage from 'grab-storage';
import 'grab-ui/dist/theme.less';
import App from './App';
import './index.less';
window.Grab = {
    config: {},
    router: {},
    storage: Storage,
};
const LOCALES = ['en', 'id', 'ms', 'th', 'tl', 'vi'];
const locale = getLocaleFromBrowserLanguage({
    defaultLocale: 'en',
    availableLocales: LOCALES,
    navigator: window.navigator,
});
const renderApp = () => {
    render(React.createElement(BrowserRouter, null,
        React.createElement(App, null)), document.getElementById('root'));
};
window
    .fetch(require('config.json'))
    .then((configResponse) => __awaiter(void 0, void 0, void 0, function* () {
    if (configResponse.status !== 200)
        return;
    window.Grab.config = yield configResponse.json();
    window
        .fetch(require(`public/locales/${locale}.json`))
        .then((localeResponse) => __awaiter(void 0, void 0, void 0, function* () {
        if (localeResponse.status !== 200)
            return;
        I18n.translations[locale] = yield localeResponse.json();
        I18n.locale = locale;
        if (window.Grab.config.sentryDsn) {
            Raven.config(window.Grab.config.sentryDsn, {
                release: `starter-rest@${process.env.APP_VERSION}`,
            }).install();
        }
        renderApp();
    }))
        .catch(err => {
        Raven.captureException(err);
        console.error(err);
    });
}))
    .catch(err => {
    Raven.captureException(err);
    console.error(err);
});
if (module.hot) {
    module.hot.accept('./App', () => {
        renderApp();
    });
}
