import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Menu } from 'grab-ui';
import { getCurrentUser, logout } from 'grab-login/lib/helper';
import styles from './AppBar.less';
const { Item } = Menu;
const { Header } = Layout;
export const AppBar = (props) => {
    const currentUser = getCurrentUser();
    const handleClick = (item) => {
        switch (item.key) {
            case 'logout':
                logout();
                props.history.replace({ pathname: '/login' });
                break;
            default:
        }
    };
    return (React.createElement(Header, { className: styles.container }));
};
AppBar.displayName = 'AppBar';
export default withRouter(AppBar);
