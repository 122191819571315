import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Layout } from 'grab-ui';
import classnames from 'classnames';
import withServiceWorker from 'grab-service-worker';
import { renderAsync } from './Routes';
import AppBar from './AppBar';
import AppContent from './AppContent';
import AppSider from './AppSider';
import styles from './App.less';
import '@antv/xflow/dist/index.css';
import 'antd/dist/antd.less';
import "./App.css";
class App extends React.Component {
    componentDidMount() {
        window.Grab.router = {
            history: this.props.history,
            location: this.props.location,
            match: this.props.match,
            staticContext: this.props.staticContext,
        };
    }
    render() {
        return (React.createElement(Switch, null,
            React.createElement(Route, { key: "login", path: "/login", component: (props) => renderAsync(() => import('./sessions/LoginPage'), props, false) }),
            React.createElement(Route, { key: "forgotPassword", path: "/forgot_password", component: (props) => renderAsync(() => import('./sessions/ForgotPasswordPage'), props, false) }),
            React.createElement(Route, { key: "project", path: "/project/:id", component: (props) => renderAsync(() => import('./projectdesigner/ProjectDesigner'), props, false) }),
            React.createElement(Route, { render: () => (React.createElement(Layout, { className: classnames(styles.layout, {
                        'ant-layout-has-sider': true,
                    }) },
                    React.createElement(AppSider, null),
                    React.createElement(Layout, null,
                        React.createElement(AppBar, null),
                        React.createElement(AppContent, null)))) })));
    }
}
App.defaultProps = {
    history: {},
    location: {},
    match: {},
    staticContext: {},
};
export default withRouter(withServiceWorker()(App));
