import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { isAuthenticated } from 'grab-login/lib/helper';
import Bundle from './Bundle';
import Spinner from './Spinner';
export const renderAsync = (asyncLoad, _props, authRequired = true) => {
    const bundleComp = (React.createElement(Bundle, { load: asyncLoad }, (Component) => Component ? React.createElement(Component, Object.assign({}, _props)) : React.createElement(Spinner, { size: "large" })));
    if (authRequired) {
        if (!isAuthenticated()) {
            const to = { pathname: '/login' };
            if (_props && _props.location) {
                to.state = { from: _props.location };
            }
            return React.createElement(Redirect, { to: to });
        }
    }
    return bundleComp;
};
const renderRoutes = (routes) => routes.map(route => {
    if (route.children) {
        return renderRoutes(route.children);
    }
    return (React.createElement(Route, { key: route.pathname, path: route.pathname, exact: route.exact, component: route.component }));
});
export const ROUTES = [
    {
        label: "projectPage.sider.routes.all",
        icon: React.createElement(HomeOutlined, null),
        pathname: '/projects',
        exact: true,
        component: (props) => renderAsync(() => import('./projects/ListProjectPage'), props, false),
    }
];
export default function Routes() {
    return (React.createElement(Switch, null,
        renderRoutes(ROUTES),
        React.createElement(Route, { key: "notFound", component: (props) => renderAsync(() => import('./NotFound'), props) })));
}
Routes.displayName = 'Routes';
