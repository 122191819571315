import React from 'react';
import { Spin } from 'grab-ui';
import classnames from 'classnames';
import styles from './Spinner.less';
export default class Spinner extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {};
    }
    render() {
        const { size } = this.props;
        return (React.createElement("div", { className: classnames(styles.wrapper) },
            React.createElement(Spin, { size: size })));
    }
}
Spinner.defaultProps = {
    size: 'default',
};
