import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu } from 'grab-ui';
import i18n from 'i18n-js';
import styles from './AppSider.less';
import { ROUTES } from './Routes';
const { Item, SubMenu } = Menu;
const { Sider } = Layout;
const getOpenKeys = (pathname) => {
    const splits = pathname.split('/');
    return splits.length > 1 ? [splits[1]] : [pathname];
};
class Sidebar extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            openKeys: getOpenKeys(this.props.location.pathname),
            selectedKeys: [this.props.location.pathname],
        };
        this.handleClick = (item) => this.props.history.push(item.key);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { location } = nextProps;
        this.setState({ selectedKeys: [location.pathname] });
    }
    render() {
        return (React.createElement(Sider, { className: styles.sider },
            React.createElement(Link, { className: styles.logoWrapper, to: '/' },
                React.createElement("img", { className: styles.logo, alt: 'Grab', src: require('public/images/brand_grey.svg') })),
            React.createElement(Menu, { className: styles.siderMenu, theme: "light", mode: "inline", defaultOpenKeys: this.state.openKeys, selectedKeys: this.state.selectedKeys, onClick: this.handleClick }, ROUTES.map(route => React.createElement(Item, { key: route.pathname },
                React.createElement("span", null,
                    route.icon,
                    React.createElement("span", null, i18n.t(route.label))))))));
    }
}
export default withRouter(Sidebar);
